@import "~antd/dist/antd.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.ant-tooltip {
    max-width: 300px;
}

.analytics__filters {
    padding-bottom: 50px;
}

.actual-price__updated-at {
    overflow-wrap: normal !important;
}

.analytics-select-item__address {
    color: grey;
    opacity: 0.8;
}
