.column-filter-list {
    .ant-checkbox-group-item {
        display: block;
        margin-right: 8px;
        padding: 16px 8px 0 16px;
    }
}

@media only screen and (max-width: 767px) {
    .prices-extras-buttons__dropdown {
        margin-right: 16px;
    }
}

@media only screen and (max-width: 460px) {
    .prices-extras-buttons__report {
        display: none;
    }
    .prices-extras-buttons__divider {
        display: none;
    }
}
