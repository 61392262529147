.actual-price-history-modal {
  width: 560px !important;

  &__range {
    padding-bottom: 24px;
    display: flex;
    gap: 10px;
    display: flex;
    justify-content: space-between;

    .ant-picker-range {
      margin-right: 10px;
      height: 32px;
    }

    .ant-picker-input > input:placeholder-shown,
    .ant-picker-range-separator > span,
    .ant-picker-suffix {
      font-size: 9px !important;
    }
  }

  &__button {
    font-size: 12px !important;
  }

  &__table {
    table {
      font-size: 12px !important;
    }

    .download-button {
      font-size: 20px !important;
      padding: 0;
      width: fit-content;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 8px !important;
    }
    .grey-tag svg {
      animation: timeAnimation 2s ease-in-out infinite;
    }
    @keyframes timeAnimation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .actual-price-history-modal {
    width: calc(100vw * 0.75) !important;
  }
}
