.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse {
  background: none;
  border: none !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 1.5715 !important;
}

.map-tooltip {
  position: absolute;
  margin-top: 10px;
  margin-right: 10px;
  width: 260px;
  top: 0;
  right: -300px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);

  &__open {
    animation: openTooltip 0.5s ease-in-out;
    right: 0;
  }

  &__hidden {
    animation: closeTooltip 0.5s ease-in-out;
    right: -300px;
  }

  &__close:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 50%;
  }

  &__prices-item {
    padding: 0;
    .ant-list-item-meta-title {
      font-weight: bold;
      color: #1890ff;
    }
  }

  &__competitor-item {
    padding: 0;
  }

  .ant-card-body {
    padding: 0;
    max-height: 600px !important;
    overflow: auto;
    scroll-behavior: smooth;
  }

  &__gas-stations {
    padding: 12px 24px 0 24px;
  }

  &__competitors {
    border-top: 1px solid #f0f0f0;
  }

  .ant-card-head-wrapper {
    align-items: baseline;
  }
}

.map-tooltip-loading {
  padding: 12px 24px 0 24px;
}

.ant-list-split .ant-list-item {
  border-bottom: none;
}

.title-secondary {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
  white-space: normal;
}

.color-list-item {
  color: #1890ff;
}

@keyframes openTooltip {
  0% {
    right: -300px;
  }
  100% {
    right: 0;
  }
}

@keyframes closeTooltip {
  0% {
    right: 0;
  }
  100% {
    right: -300px;
  }
}

@keyframes openTooltipMobile {
  0% {
    top: 95vh;
  }

  100% {
    top: 2px;
  }
}

@keyframes closeTooltipMobile {
  0% {
    top: 2px;
  }

  100% {
    top: 95vh;
  }
}

@media screen and (max-width: 580px) {
  .map-tooltip {
    position: absolute;
    background-color: hsla(0, 0%, 100%, 0.9);
    margin-top: 0;
    width: 100%;
    height: 100%;
    top: 95vh;
    left: 0;
    z-index: 5555;

    &__open {
      animation: openTooltipMobile 0.5s ease-in-out;
      top: 2px;
    }

    &__hidden {
      animation: closeTooltipMobile 0.5s ease-in-out;
      top: 95vh;
    }
  }
}
